
import axios from "axios";
import { Abstract } from './Abstract'; 

export class InventoryFamily extends Abstract 
{ 
  constructor(session) { 
    super('Inventory/InventoryFamily', session) ;
    this.name = null;
    this.key_name = null;
    this.id_inventory_warehouse = null;
    this.id_inventory_subwarehouse = null;
  }
    
  async familiy(SubAlmacen) {
    let response = await axios.get(this.controller + '/family', {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            subalmacen:SubAlmacen
        }
    });
    return response.data;
  }
  
  async getCustom() {
    let response = await axios.get(this.controller + "/getCustom", {
        headers: {
            usuario: this.usuario,
            empresa: this.intEmpresa,
            sucursal: this.intSucursal
        }
    });
    return response.data;
  }
} 
 
