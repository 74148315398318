<template>
      <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Grupo Contable" :rules="rules" :headers="headers" :inputs="inputs" />
</template>
<script>
import {  InventoryGroup } from '../../../models/inventarios/InventoryGroup';
import {  InventoryFamily } from '../../../models/inventarios/InventoryFamily';
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import Catalog from "../../../components/templates/BasicCatalog.vue";

export default {
    data() {
    return {
      family: [],
      entity: new InventoryGroup(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", {cols: 6}),
        new CatalogInput("Descripcion", "key_name", "text", {cols: 6}),
        new CatalogInput("Familia", "id_inventory_family", "dropdown", { options: [], options_value: 'id', options_label: 'name', cols: 4}),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "id_inventory_family"})
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name")
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new InventoryGroup(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InventoryGroup(this.session); 
    this.family = await new InventoryFamily(this.session).all();
    this.inputs[2].options = this.family;
  }
};
</script> 
 
<style scoped lang="scss">
</style > 
  