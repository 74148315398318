
import axios from "axios";
import { Abstract } from './Abstract'; 
export class InventoryGroup extends Abstract 
{ 
  constructor(session) 
  { 
    super('Inventory/InventoryGroup', session);
    this.name = null;
    this.key_name = null;
    this.id_inventory_family = null;
  }
    
  async Grupo(Familia) {
    let response = await axios.get(this.controller + '/Group', {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            familia:Familia
        }
    });
    return response.data;
  }

  async getCustom() {
    let response = await axios.get(this.controller + "/getCustom", {
        headers: {
            usuario: this.usuario,
            empresa: this.intEmpresa,
            sucursal: this.intSucursal
        }
    });
    return response.data;
  }
}